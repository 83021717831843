import copy from 'copy-to-clipboard';
export const showReadableBoolValue = (value: boolean) => {
  return value ? 'Yes' : 'No';
}


export const validateInput = (value: string) => {
  const regex = new RegExp(/^[A-Za-z0-9]{15,20}$/);
  const isValid = regex.test(value);

  return isValid;
}

export const convertTimestampToDateFormat = (timestamp: string) => {
  const date = new Date(+timestamp).toLocaleDateString("no-NO");
  const formatDate = date.split('/').join('.');
  return formatDate;
}

export const convertTimestampToTimeFormat = (timestamp: string) => {
  const time = new Date(+timestamp).toLocaleTimeString('en-GB');
  return time;
}

export const getPathname = (url: string | null) => {
  const pattern = /\((.*?)\)/;
  const matchedPathname = url?.match(pattern);

  if (matchedPathname) {
    return matchedPathname[1];
  } else {
    return null;
  }
}

export const getPagename = (url: string) => {

  const indexOfBracket = url?.indexOf('(');
  if (indexOfBracket !== -1) {
    // Use slice to get the substring before the opening parenthesis
    return url?.slice(0, indexOfBracket);
  } else {
    // Return the original string if no opening parenthesis is found
    return url;
  }
}

export const getEventname = (name: string) => {

  const indexOfBracket = name?.indexOf(':');
  if (indexOfBracket !== -1) {
    // Use slice to get the substring before the opening parenthesis
    return name?.slice(0, indexOfBracket);
  } else {
    // Return the original string if no opening parenthesis is found
    return name;
  }
}

export const getTodayEvents = (timestamp: string) => {
  const currentTimestamp = new Date()
  const targetTimestamp = new Date(+timestamp)
  return (
    currentTimestamp.getFullYear() === targetTimestamp.getFullYear() &&
    currentTimestamp.getMonth() === targetTimestamp.getMonth() &&
    currentTimestamp.getDate() === targetTimestamp.getDate()
  );

}


export const getYesterdayEvents = (timestamp: string): boolean => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1); // Subtract 1 day to get yesterday

  const dateFromTimestamp = new Date(+timestamp);

  return (
    yesterday.getFullYear() === dateFromTimestamp.getFullYear() &&
    yesterday.getMonth() === dateFromTimestamp.getMonth() &&
    yesterday.getDate() === dateFromTimestamp.getDate()
  );
};

export const getWithinTwoWeeksEvents = (timestamp: string): boolean => {
  const currentDate = new Date();
  const dateFromTimestamp = new Date(+timestamp);
  const twoWeeksLater = new Date(currentDate.getTime() - (14 * 24 * 60 * 60 * 1000)); // 14 days in milliseconds

  return dateFromTimestamp.getTime() >= twoWeeksLater.getTime();
};

export const getForTwoHoursEvents = (timestamp: string): boolean => {
  const currentTimestamp = new Date().getTime();
  const dateFromTimestamp = new Date(+timestamp).getTime();
  const twoHoursLater = currentTimestamp - (2 * 60 * 60 * 1000); // 2 hours in milliseconds
  return dateFromTimestamp >= twoHoursLater;

};

export const copyUrl = (urlToCopy: string, setCopied: (copied: boolean) => void) => {
  setCopied(true);
  urlToCopy && copy(urlToCopy);
  setTimeout(() => {
    setCopied(false);
  }, 3000);
};