import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'

import { GridContentCXM } from '../Grid/styles';


export const PageTitleContent = styled(GridContentCXM)`
  ${media.desktop`
         grid-column: 1 / span 6;
  `}
`