import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'



export const NextBestOfferCard = styled.div`
  width:350px;
  min-height:100%;
  font-size: var(--font-size-small);
  line-height: var(--line-height-basis);
  padding:var(--spacing-medium);  
  border-radius:8px;
  margin:var(--spacing-small);
  background-color:var(--color-sand-yellow);
  height: fit-content;

  ${media.xLargeDesktop`
       width:auto;
  `}

  ${media.phoneTablet`
       width:250px;
       margin:var(--spacing-x-small);
  `}
}
`
export const NextBestOfferTitle = styled.div`
  font-size: var(--font-size-medium);
  line-height: 28px;
  color:var(--color-black-80);
  font-weight:500;
  margin-bottom:var(--spacing-small);
`
export const NextBestOfferMessage = styled.div`
  font-size: var(--font-size-basis);
  line-height: var(--line-height-basis);
`

export const NextBestOfferPanel = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 20px;

   ${media.phoneTablet`
   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  `}
`



