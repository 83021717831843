import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'
import { CXMSection, GridContentCXM } from '../Grid/styles';
import { Button, Heading } from '@dnb/eufemia';
import Grid from '../Grid';


export const CustomerSectionProfile = styled(CXMSection)`
  position:relative;
`
export const CustomerSectionProfileGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr)!important;
`
export const CustomerContent = styled(GridContentCXM)`
  ${media.desktop`
     grid-column: span 3;
  `}
    ${media.smallDesktop`
     grid-column: span 4;
  `}

  ${media.phoneTablet`
    grid-column: span 6;
  `}
`
export const AccordianBorder = styled.div`
  border: 1px solid var(--color-black-20);
  border-radius: 4px;
  padding: var(--spacing-small);
`
export const CustomerProfileHeading = styled(Heading)`
  margin-bottom:var(--spacing-x-small);  
`

export const CustomerProfileActiveStatus = styled.span`
  font-size:var(--font-size-basis); 
`

export const CopyButtonStyle = styled(Button)`
  color:var(--color-black)!important;  
  --border-color:white!important; 
  padding-left:0!important; 
`