import React, { useEffect, useState } from 'react';
import { Accordion, IconPrimary } from '@dnb/eufemia';
import { account_medium, funds, information_circled } from '@dnb/eufemia/icons';
import { NextBestOffer } from 'hooks/CustomerInfoContext/types';
import {
  LANGUAGE,
  NEXT_BEST_OFFERS_INFO_TITLE
} from 'utils/constants';
import { getNextBestOffersFromQuery } from 'utils/graphql.utils';
import { CXMSection } from '../Grid/styles';
import {
  NextBestOfferCard,
  NextBestOfferMessage,
  NextBestOfferPanel,
  NextBestOfferTitle,
} from './styles';

type NextBestOfferType = {
  partyIdentifier: string | undefined;
  ecId: string | undefined;
};

export const NextBestOfferSection = ({
  partyIdentifier,
  ecId,
}: NextBestOfferType) => {
  const [nextBestOffers, setNextBestOffers] = useState<NextBestOffer[]>();

  async function getNextBestOffer({
    partyIdentifier,
    ecId,
  }: NextBestOfferType) {
    if (partyIdentifier && ecId) {
      try {
        const result = await getNextBestOffersFromQuery(
          partyIdentifier,
          ecId,
          LANGUAGE
        );
        if (result?.data?.getNextBestOffer?.offers?.length > 0) {
          setNextBestOffers(result.data.getNextBestOffer.offers);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  const getIcon = (icon: string | null) => {
    switch (icon?.toLowerCase()) {
      case 'funds':
        return funds;
      case 'loan':
        return account_medium;
      case 'information':
        return information_circled;
      default:
        return account_medium;
    }
  };
  useEffect(() => {
    if (partyIdentifier) {

      getNextBestOffer({
        partyIdentifier,
        ecId,
      });
    }
  }, [partyIdentifier, ecId]);
  const validOffers =
    partyIdentifier && nextBestOffers && nextBestOffers?.length > 0;

  return validOffers ? (
    <CXMSection>
      <Accordion expanded={false}>
        <Accordion.Header
          title={NEXT_BEST_OFFERS_INFO_TITLE}
          data-testid="nbosection"
        ></Accordion.Header>
        <Accordion.Content>
          <NextBestOfferPanel>
            {nextBestOffers?.map((offer, index: number) => (
              <NextBestOfferCard key={index}>
                <NextBestOfferTitle>
                  <IconPrimary
                    icon={getIcon(offer?.illustration)}
                    right="small"
                    bottom="0"
                    size="medium"
                  />
                  {offer?.title}
                </NextBestOfferTitle>
                <NextBestOfferMessage>{offer?.message}</NextBestOfferMessage>
              </NextBestOfferCard>
            ))}
          </NextBestOfferPanel>
        </Accordion.Content>
      </Accordion>
    </CXMSection>
  ) : null;
};
