import React from 'react';
import { Heading } from '@dnb/eufemia';
import { CXM_PAGE_TITLE } from 'utils/constants';
import Grid from '../Grid';
import { CXMPageTitleSection } from '../Grid/styles';
import { PageTitleContent } from './styles';

export const PageTitle = () => {
  return (
    <CXMPageTitleSection>
      <Grid>
        <PageTitleContent>
          <Heading size="x-large" top="x-small" bottom="small">
            {CXM_PAGE_TITLE}
          </Heading>
        </PageTitleContent>
      </Grid>
    </CXMPageTitleSection>
  );
};
