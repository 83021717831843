import HTMLReactParser from 'html-react-parser';
import React, { useState } from 'react';
import { Drawer, H3, P, ProgressIndicator } from '@dnb/eufemia';
import { play_medium } from '@dnb/eufemia/icons';
import { EmailEvent, PreviewEmail } from 'hooks/CustomerInfoContext/types';
import { getPreviewFromQuery } from 'utils/graphql.utils';
import { CustomerContent } from '../CustomerProfile/styles';
import Grid, { CXMSection } from '../Grid';
import { LoaderWrapper } from '../layout/styles';

export const EmailPreview = ({
  eventData,
  partyID,
}: {
  eventData: EmailEvent;
  partyID: string;
}) => {
  const [emailData, setEmailData] = useState<PreviewEmail>();
  const [previewLoader, setPreviewLoader] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  if (!partyID || !eventData.messageId) {
    return null;
  }
  const getEmailData = async () => {
    try {
      setPreviewLoader(true);
      setPreviewError(false);
      const data = await getPreviewFromQuery(eventData.messageId, partyID);
      setPreviewLoader(false);
      setEmailData(data);
      return data;
    } catch (e: any) {
      setPreviewLoader(false);
      setPreviewError(true);
    }
  };

  return (
    <CXMSection>
      <Grid>
        <CustomerContent>
          <Drawer
            triggerAttributes={{
              variant: 'tertiary',
              icon: play_medium,
              on_click: () => getEmailData(),
              title: 'Preview',
              icon_position: 'left',
              disabled: true,
            }}
            minWidth="45rem"
          >
            {!previewLoader && emailData?.data.getEmailPreview ? (
              <>
                <H3>Subject - {emailData?.data.getEmailPreview.subject}</H3>
                <P style={{ pointerEvents: 'none' }}>
                  {emailData &&
                    HTMLReactParser(emailData.data.getEmailPreview.html)}
                </P>
              </>
            ) : (
              !previewError && (
                <LoaderWrapper>
                  <ProgressIndicator type="circular" size="large" />
                </LoaderWrapper>
              )
            )}
            {previewError && (
              <P top bottom medium>
                Preview Not available
              </P>
            )}
          </Drawer>
        </CustomerContent>
      </Grid>
    </CXMSection>
  );
};
