import React, { useState } from 'react';
import { Drawer, H3, P } from '@dnb/eufemia';
import { play_medium } from '@dnb/eufemia/icons';
import { SmsEvent } from '@/hooks/CustomerInfoContext/types';
import { CustomerContent } from '../CustomerProfile/styles';
import Grid, { CXMSection } from '../Grid';
import { SmsSection } from './styles';

export const SmsPreview = ({ eventData }: { eventData: SmsEvent }) => {
  const [smsData, setSmsData] = useState<SmsEvent>();

  const getSmsData = () => {
    setSmsData(eventData);
    return smsData;
  };

  return (
    <CXMSection>
      <Grid>
        <CustomerContent>
          <Drawer
            triggerAttributes={{
              variant: 'tertiary',
              icon: play_medium,
              on_click: () => getSmsData(),
              title: 'Preview',
              icon_position: 'left',
            }}
            minWidth="45rem"
          >
            {smsData?.messageContent ? (
              <SmsSection>
                <H3 color="red">{smsData.messageContent}</H3>
              </SmsSection>
            ) : (
              <P top bottom medium>
                Preview Not available
              </P>
            )}
          </Drawer>
        </CustomerContent>
      </Grid>
    </CXMSection>
  );
};
