import React from 'react';
import { Accordion, Heading, Span } from '@dnb/eufemia';
import { Dl, Dt, Dd } from '@dnb/eufemia/elements';
import { useCustomerInfo } from 'hooks/CustomerInfoContext';
import {
  CUSTOMER_PRODUCT_OWNERSHIP_INFO_TITLE,
  CUSTOMER_PRODUCT_OWNERSHIP_HEADING_TITLE,
  CUSTOMER_PRODUCT_OWNERSHIP_PROGRAM_INFO,
  CUSTOMER_PRODUCT_OWNERSHIP_ASSOCIATION_HEADING_TITLE,
  CUSTOMER_PRODUCT_OWNERSHIP_BFA_TITLE,
} from '../../utils/constants';
import { AccordianBorder } from '../CustomerProfile/styles';
import { CXMSection } from '../Grid/styles';
import { AccordionMapper } from './styles';

export const CustomerInformationProductOwnership = () => {
  const personInfo = useCustomerInfo();
  const programInfo =
    personInfo?.profile?._dnb?.customerAttributes?.loyaltyProgramName;
  const bfaInfo = personInfo?.profile?._dnb?.customerAttributes?.bfaAssociation;

  return (
    <CXMSection>
      <Accordion expanded>
        <Accordion.Header title={CUSTOMER_PRODUCT_OWNERSHIP_INFO_TITLE}>
          {CUSTOMER_PRODUCT_OWNERSHIP_INFO_TITLE}
        </Accordion.Header>
        <AccordionMapper>
          <Accordion.Content>
            <AccordianBorder>
              <Heading>{CUSTOMER_PRODUCT_OWNERSHIP_HEADING_TITLE}</Heading>
              <Dl>
                {programInfo && (
                  <>
                    <Dt>{CUSTOMER_PRODUCT_OWNERSHIP_PROGRAM_INFO}</Dt>
                    <Dd>{programInfo}</Dd>
                  </>
                )}
              </Dl>
            </AccordianBorder>
          </Accordion.Content>
          {bfaInfo && Array.isArray(bfaInfo) && bfaInfo?.length > 0 && (
            <Accordion.Content>
              <AccordianBorder>
                <Heading>
                  {CUSTOMER_PRODUCT_OWNERSHIP_ASSOCIATION_HEADING_TITLE}
                </Heading>
                <Dl>
                  <Dt>{CUSTOMER_PRODUCT_OWNERSHIP_BFA_TITLE}</Dt>

                  {bfaInfo?.map(
                    (
                      showBFAInfo: {
                        name: string | undefined;
                        code: string | undefined;
                      },
                      index
                    ) => (
                      <Dd key={`${showBFAInfo.name}_${index}`}>
                        <React.Fragment>
                          <Span>{showBFAInfo.name}</Span>
                          <br />
                          <Span bottom="x-small"> {showBFAInfo.code}</Span>
                          <br />
                        </React.Fragment>
                      </Dd>
                    )
                  )}
                </Dl>
              </AccordianBorder>
            </Accordion.Content>
          )}
        </AccordionMapper>
      </Accordion>
    </CXMSection>
  );
};
