import React, { useEffect, useState } from 'react';
import { ProgressIndicator } from '@dnb/eufemia';
import {
  ChannelNameType,
  TimeFilter,
  CustomerInfoResult,
  ExperienceEvent,
} from 'hooks/CustomerInfoContext/types';
import { getResultFromQuery } from 'utils/graphql.utils';
import {
  getForTwoHoursEvents,
  getTodayEvents,
  getWithinTwoWeeksEvents,
  getYesterdayEvents,
} from 'utils/helperFunction';
import { LoaderWrapper } from '../layout/styles';
import { TimelinesCallcenter } from './TimelinesCallcenter';

type CustomerEventDetailsType = {
  partyIdentifier: string | undefined;
};

export const CustomerEventDetails = ({
  partyIdentifier,
}: CustomerEventDetailsType) => {
  const [events, setEvents] = useState<ExperienceEvent[] | undefined>();
  const [originalSetOfEvents, setOriginalSetOfEvents] = useState<
    ExperienceEvent[] | undefined
  >();
  const [direction, setDirection] = useState(true);
  const [loader, setLoader] = useState(false);
  const [channelName, setChannelName] = useState<ChannelNameType>();

  async function getEvents(
    partyIdentifier: string,
    channel?: ChannelNameType,
    direction?: boolean
  ) {
    if (partyIdentifier) {
      setLoader(true);
      try {
        const result: CustomerInfoResult = await getResultFromQuery(
          partyIdentifier,
          direction ? 'DESC' : 'ASC',
          channel
        );
        if (result.data) {
          setChannelName(channel);
          setEvents(result?.data?.getCustomerProfile?.events);
          setOriginalSetOfEvents(result?.data?.getCustomerProfile?.events);
          setLoader(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleChannelNameFilter = (filterChannel?: ChannelNameType) => {
    if (channelName !== filterChannel || filterChannel === undefined) {
      setChannelName(filterChannel);
    }
  };

  const handleFilterByTime = (time?: string) => {
    if (time === TimeFilter.Today) {
      setEvents(
        originalSetOfEvents?.filter((a) => getTodayEvents(a.timestamp))
      );
    } else if (time === TimeFilter.Yesterday) {
      setEvents(
        originalSetOfEvents?.filter((a) => getYesterdayEvents(a.timestamp))
      );
    } else if (time === TimeFilter.TwoWeeks) {
      setEvents(
        originalSetOfEvents?.filter((a) => getWithinTwoWeeksEvents(a.timestamp))
      );
    } else if (time === TimeFilter.TwoHours) {
      setEvents(
        originalSetOfEvents?.filter((a) => getForTwoHoursEvents(a.timestamp))
      );
    } else if (time === TimeFilter.Reset) {
      !channelName &&
        partyIdentifier &&
        getEvents(partyIdentifier, channelName, direction);
    }
  };

  const handleSortDirection = () => {
    setDirection(!direction);
  };

  useEffect(() => {
    if (partyIdentifier) {
      setLoader(true);
      getEvents(partyIdentifier, channelName, direction);
    }
  }, [partyIdentifier, channelName, direction]);

  return (
    <div>
      {partyIdentifier && events ? (
        <TimelinesCallcenter
          partyID={partyIdentifier}
          sortByTimestampClickHandler={handleSortDirection}
          filterByChannelName={handleChannelNameFilter}
          filterByTime={handleFilterByTime}
          events={events}
        />
      ) : (
        loader && (
          <LoaderWrapper>
            <ProgressIndicator type="circular" size="large" />
          </LoaderWrapper>
        )
      )}
    </div>
  );
};
