import React, { FC, KeyboardEventHandler, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import {
  CXM_SEARCH_INPUT_PLACEHOLDER,
  CXM_SEARCH_BUTTON_TEXT,
  CXM_SEARCH_PATH,
  CXM_SEARCH_INVALID_PARTYID_MESSAGE,
  CXM_PARTY_ID_PARAM,
} from 'utils/constants';
import { pushErrorPageLoadedEvent } from 'utils/datalayer';
import { validateInput } from 'utils/helperFunction';
import Grid from '../Grid';
import { CXMSection } from '../Grid/styles';
import {
  SearchBarContent,
  SearchContainer,
  SearchContent,
  SearchCXMButton,
} from './styles';
import { Error, Validation } from './types';

interface SearchCallcenterProps {
  onSearchClickHandler: (partyID: string) => void;
  setError: (setErrorProps: Error) => void;
  resetError: () => void;
  validationErrorState: Validation | undefined;
}
export const SearchCallcenter: FC<SearchCallcenterProps> = ({
  onSearchClickHandler,
  setError,
  resetError,
  validationErrorState,
}) => {
  const [inputContent, setInputContent] = useState('');
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const partyIDFromSearchPage = queryParameters.get(CXM_PARTY_ID_PARAM);
  const getPartyIDFromLocalStorage = localStorage.getItem(CXM_PARTY_ID_PARAM);
  getPartyIDFromLocalStorage && localStorage.removeItem(CXM_PARTY_ID_PARAM);
  const searchForPartyID = getPartyIDFromLocalStorage ?? partyIDFromSearchPage;

  useEffect(() => {
    if (searchForPartyID !== '' && searchForPartyID) {
      setInputContent(searchForPartyID);
      onSearchClickHandler(searchForPartyID);
    }
  }, [searchForPartyID]);

  const onClickHandler = () => {
    const checkValidInput = validateInput(inputContent);
    if (!checkValidInput) {
      setError({
        isValidationError: true,
      });

      pushErrorPageLoadedEvent(CXM_SEARCH_PATH, {
        type: 'Form Field Error',
        message: CXM_SEARCH_INVALID_PARTYID_MESSAGE,
        value: inputContent,
      });
    } else {
      onSearchClickHandler(inputContent);
    }
  };
  const onKeyDownHandler: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (e.key === 'Enter') {
      onClickHandler();
    }
  };
  const onChangeHandler = ({ value }: any) => {
    resetError();
    setInputContent(value);
  };

  return (
    <CXMSection>
      <Grid>
        <SearchContent>
          <SearchBarContent>
            <SearchContainer
              value={inputContent}
              size="medium"
              clear={true}
              stretch={true}
              name="inputSearchContainer"
              aria-label={CXM_SEARCH_INPUT_PLACEHOLDER}
              placeholder={CXM_SEARCH_INPUT_PLACEHOLDER}
              label_direction="vertical"
              icon="loupe"
              icon_position="left"
              icon_size={'medium'}
              on_change={onChangeHandler}
              status={validationErrorState?.message}
              status_state={validationErrorState?.status}
              onKeyDown={onKeyDownHandler}
            />
            <SearchCXMButton
              text={CXM_SEARCH_BUTTON_TEXT}
              title={CXM_SEARCH_BUTTON_TEXT}
              onClick={onClickHandler}
            />
          </SearchBarContent>
        </SearchContent>
      </Grid>
    </CXMSection>
  );
};
