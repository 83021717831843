import { calc } from '@dnb/eufemia/components/space/SpacingUtils';
import { media } from '@dnb-shared-libs/responsive';
import styled from '@emotion/styled';

export const SmsSection = styled.section`
  margin: var(--section-margin-mobile, 0 0 var(--spacing-xx-large));
  background-color: var(--color-black-3);
  border-radius: var(--spacing-small);
  border: 0.13rem solid var(--color-ocean-green);
  padding: var(--spacing-medium);

  ${media.tablet`
  margin: var(--section-margin-tablet, 0 0 ${calc('xx-large')});
`}

  ${media.desktop`
  margin: var(--section-margin-desktop, 0 0 ${calc('xx-large')});
`}
`;
