import { useContext, createContext } from 'react';
import { CustomerProfileInfo } from './types';

export const CustomerInfoContext = createContext<CustomerProfileInfo>({
  profile: undefined,
  activeStatus:false,
  events: undefined,
});

export const useCustomerInfo = (): CustomerProfileInfo => {
  return useContext(CustomerInfoContext);
};
