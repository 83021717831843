import React, { useState } from 'react';
import { Button, Span } from '@dnb/eufemia';
import {
  filter,
  mobilephone,
  email,
  web,
  reset,
  bubble,
  calendar,
} from '@dnb/eufemia/icons';
import { ChannelNameType, TimeFilter } from 'hooks/CustomerInfoContext/types';
import {
  CUSTOMER_EVENTS_EMAIL_FILTER_BUTTON,
  CUSTOMER_EVENTS_FILTER_BUTTON,
  CUSTOMER_EVENTS_RESET_BUTTON,
  CUSTOMER_EVENTS_WEB_FILTER_BUTTON,
  CUSTOMER_EVENTS_SMS_FILTER_BUTTON,
  CUSTOMER_EVENTS_SPARE_FILTER_BUTTON,
  CUSTOMER_EVENTS_MOBILEBANK_FILTER_BUTTON,
} from 'utils/constants';
import { FilterPanel, FilterStyledButton, FiterText } from './styles';

interface FilterButtonProps {
  filterData: (filterName?: ChannelNameType) => void;
  filterByTime: (time: string) => void;
  areEventsFiltered: boolean;
}

export const FilterButton = ({
  filterData,
  filterByTime,
  areEventsFiltered,
}: FilterButtonProps) => {
  const [showFilter, setShowFilter] = useState(true);
  const [channelFilter, setChannelFilter] = useState<
    ChannelNameType | undefined
  >(undefined);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(TimeFilter.Reset);

  const channelFilterClickHandler = (id: ChannelNameType) => {
    if (channelFilter === id) {
      resetHandler();
    } else {
      setTimeFilter(TimeFilter.Reset);
      setChannelFilter(id);
      filterData(id);
    }
  };

  const timeFilterClickHandler = (id: TimeFilter) => {
    if (timeFilter === id) {
      filterByTime('Reset');
      setTimeFilter(TimeFilter.Reset);
    } else {
      filterData(channelFilter);
      setTimeFilter(id);
      filterByTime(id);
    }
  };

  const onDeselectOrReset = () => {
    filterData();
    filterByTime('Reset');
  };

  const resetHandler = () => {
    setChannelFilter(undefined);
    setTimeFilter(TimeFilter.Reset);
    onDeselectOrReset();
  };

  const shouldShowResetButton = areEventsFiltered && channelFilter;

  return (
    <>
      <FilterStyledButton
        icon_position="left"
        icon={filter}
        text={CUSTOMER_EVENTS_FILTER_BUTTON}
        title={CUSTOMER_EVENTS_FILTER_BUTTON}
        variant="secondary"
        top="20px"
        right="10px"
        bottom="20px"
        onClick={() => {
          setShowFilter(!showFilter);
        }}
      />
      {shouldShowResetButton && (
        <Button
          icon_position="left"
          icon={reset}
          text={CUSTOMER_EVENTS_RESET_BUTTON}
          title={CUSTOMER_EVENTS_RESET_BUTTON}
          variant="tertiary"
          top="20px"
          bottom="20px"
          onClick={resetHandler}
        />
      )}
      {showFilter && (
        <FilterPanel>
          <FiterText>Channel Filters:</FiterText>
          <Span>
            <Button
              icon_position="left"
              icon={mobilephone}
              text={CUSTOMER_EVENTS_MOBILEBANK_FILTER_BUTTON}
              title={CUSTOMER_EVENTS_MOBILEBANK_FILTER_BUTTON}
              disabled={!areEventsFiltered}
              variant={
                channelFilter === ChannelNameType.MOBILBANK
                  ? 'primary'
                  : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              id="mobilebank"
              on_click={() =>
                channelFilterClickHandler(ChannelNameType.MOBILBANK)
              }
            />
            <Button
              icon_position="left"
              icon={mobilephone}
              text={CUSTOMER_EVENTS_SPARE_FILTER_BUTTON}
              title={CUSTOMER_EVENTS_SPARE_FILTER_BUTTON}
              disabled={!areEventsFiltered}
              variant={
                channelFilter === ChannelNameType.SPARE
                  ? 'primary'
                  : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              id="spare"
              on_click={() => channelFilterClickHandler(ChannelNameType.SPARE)}
            />
            <Button
              icon_position="left"
              icon={email}
              disabled={!areEventsFiltered}
              id="email"
              text={CUSTOMER_EVENTS_EMAIL_FILTER_BUTTON}
              title={CUSTOMER_EVENTS_EMAIL_FILTER_BUTTON}
              variant={
                channelFilter === ChannelNameType.EMAIL
                  ? 'primary'
                  : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              on_click={() => channelFilterClickHandler(ChannelNameType.EMAIL)}
            />
            <Button
              icon_position="left"
              icon={web}
              disabled={!areEventsFiltered}
              id="web"
              text={CUSTOMER_EVENTS_WEB_FILTER_BUTTON}
              title={CUSTOMER_EVENTS_WEB_FILTER_BUTTON}
              variant={
                channelFilter === ChannelNameType.WEB ? 'primary' : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              on_click={() => channelFilterClickHandler(ChannelNameType.WEB)}
            />
            <Button
              icon_position="left"
              icon={bubble}
              disabled={!areEventsFiltered}
              id="sms"
              text={CUSTOMER_EVENTS_SMS_FILTER_BUTTON}
              title={CUSTOMER_EVENTS_SMS_FILTER_BUTTON}
              variant={
                channelFilter === ChannelNameType.SMS ? 'primary' : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              on_click={() => channelFilterClickHandler(ChannelNameType.SMS)}
            />
          </Span>
        </FilterPanel>
      )}

      {showFilter && (
        <FilterPanel>
          <FiterText>Session Filters:</FiterText>
          <Span>
            <Button
              icon_position="left"
              icon={calendar}
              text="Today"
              title="Today"
              variant={
                timeFilter === TimeFilter.Today ? 'primary' : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              id="today"
              disabled={!areEventsFiltered}
              on_click={() => timeFilterClickHandler(TimeFilter.Today)}
            />
            <Button
              icon_position="left"
              icon={calendar}
              id="yesterday"
              text="Yesterday"
              title="Yesterday"
              variant={
                timeFilter === TimeFilter.Yesterday ? 'primary' : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              disabled={!areEventsFiltered}
              on_click={() => timeFilterClickHandler(TimeFilter.Yesterday)}
            />
            <Button
              icon_position="left"
              icon={calendar}
              id="twoWeeks"
              text="Two Weeks"
              title="Two Weeks"
              variant={
                timeFilter === TimeFilter.TwoWeeks ? 'primary' : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              disabled={!areEventsFiltered}
              on_click={() => timeFilterClickHandler(TimeFilter.TwoWeeks)}
            />
            <Button
              icon_position="left"
              icon={calendar}
              id="twoHours"
              text="Two Hours"
              title="Two Hours"
              variant={
                timeFilter === TimeFilter.TwoHours ? 'primary' : 'secondary'
              }
              top="20px"
              right="10px"
              bottom="20px"
              disabled={!areEventsFiltered}
              on_click={() => timeFilterClickHandler(TimeFilter.TwoHours)}
            />
          </Span>
        </FilterPanel>
      )}
    </>
  );
};
