import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { CustomerInfoResult, PreviewEmail, ChannelNameType } from 'hooks/CustomerInfoContext/types';

export const getNextBestOffers = `
query GetNextBestOfferQuery ($hashedPartyId: String!, $ecId: String!, $language:String!, $placementNames:[String!]){
  getNextBestOffer(
    partyId: $hashedPartyId
    ecId: $ecId
    language:$language
    placementNames:$placementNames
  ) {
      offers {
        contentType
        ctaLabel
        ctaLink
        id
        illustration
        location
        message
        title
    }
  }
}
`

export const getCustomerData = `
query GetCustomerProfileQuery ($hashedPartyId: String!,$sortByTimestamp: String,$filterByChannel: EventType){
  getCustomerProfile(
    hashedPartyId: $hashedPartyId
    sortByTimestamp: $sortByTimestamp
    filterByChannel: $filterByChannel
  ) {
    events {
      channelName
      eventMetadata {
        ... on WebEvent {
          __typename
          webServer
          name
          language
          operatingSystem
          operatingSystemVendor
          operatingSystemVersion
          deviceType
          browserDetailsVersion
          browserDetailsVendor
          browserDetailsName
        }
        ... on EmailEvent {
          __typename
          to
          sendTime
          messageId
        }
        ... on MobilBankEvent {
          __typename
          mobileServer
          name
          language
          operatingSystem
          operatingSystemVersion
          operatingSystemVendor
          manufacturer
          deviceType
          applicationVersion
        }
        ... on SpareEvent {
          __typename
          mobileServer
          name
          language
          operatingSystem
          operatingSystemVersion
          operatingSystemVendor
          manufacturer
          deviceType
          applicationVersion
        }
        ... on SmsEvent {
          messageName
          contactDetails
          messageContent
        }
      }
      timestamp
    }
  }
}
`

export const getCustomerProfileQuery = `
query GetCustomerProfileQuery ($hashedPartyId: String!){
  getCustomerProfile(
    hashedPartyId: $hashedPartyId
  ) {
    activeStatus
    profile {
      _dnb {
        customerAttributes {
          bfaAssociation {
            code
            name
          }
          loyaltyProgramName
          employeeFlag
          ecId
        }
      }
      person {
        _dnb {
          age
          isDeceasedInd
        }
        gender
        name {
          firstName
          lastName
        }
      }
      personalEmail {
        address
      }
      mobilePhone {
        number
      }
    }
  }
}
`


export const getEmailPreview = `

 query GetEmailPreviewQuery ($messageId: String!,$hashedPartyId:String!) {
  getEmailPreview(
    messageId: $messageId,
    hashedPartyId:$hashedPartyId
  ) {
    html
    subject
  }
}`

export async function getNextBestOffersFromQuery(input: string, ecid: string, language: string) {
  const headers = {
    'channel-name': 'callcenter'
  };
  
  return await API.graphql({
    query: getNextBestOffers,
    variables: { hashedPartyId: input, ecId: ecid, language },
    authMode: process.env.NODE_ENV === 'development' ? GRAPHQL_AUTH_MODE.API_KEY : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  },headers) as CustomerInfoResult;
}


export async function getResultFromQuery(input: string, sortByTimestamp: string, filterByChannel?: ChannelNameType) {
  return await API.graphql({
    query: getCustomerData,
    variables: { hashedPartyId: input, sortByTimestamp, filterByChannel },
    authMode: process.env.NODE_ENV === 'development' ? GRAPHQL_AUTH_MODE.API_KEY : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  }) as CustomerInfoResult;
}

export async function getCustomerProfileFromQuery(hashedPartyId: string) {
  return await API.graphql({
    query: getCustomerProfileQuery,
    variables: { hashedPartyId },
    authMode: process.env.NODE_ENV === 'development' ? GRAPHQL_AUTH_MODE.API_KEY : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  }) as CustomerInfoResult;
}

export async function getPreviewFromQuery(messageId: string, partyId: string) {
  return await API.graphql({
    query: getEmailPreview,
    variables: { messageId: messageId, hashedPartyId: partyId },
    authMode: process.env.NODE_ENV === 'development' ? GRAPHQL_AUTH_MODE.API_KEY : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  }) as PreviewEmail;
}
