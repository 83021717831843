import styled from '@emotion/styled'
import { Drawer, H3, P } from '@dnb/eufemia';



export const DeviceInfoHeading = styled(H3)`
  margin:0 0 var(--spacing-x-small) var(--spacing-small)!important;  
  color: var(--color-emerald-green)!important;
  list-style-type: square;
  display: list-item;
`

export const DeviceInfoDetails = styled(P)`
   margin: 0 0 var(--spacing-medium) var(--spacing-small)!important; 
   color: var(--color-emerald-green)!important;
   pointer-events: none;
`

export const DeviceInfoModal = styled(Drawer)`
.dnb-section--white {
   margin: 0 0 var(--spacing-medium) var(--spacing-small)!important; 
   color: var(--color-emerald-green)!important;
}
`