import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'
import { GridContentCXM } from '../Grid/styles';


export const CustomerMoreDetails = styled(GridContentCXM)`
  ${media.desktop`
    grid-column: 5 / span 12;
  `}
  ${media.smallDesktop`
    grid-column: 6 / span 12;
  `}  
  ${media.phoneTablet`
    grid-column: span 6;
  `}
`

export const AccordionMapper = styled.div`
  display:flex;
  flex-direction:column;
`