import React, { useState } from 'react';
import { Accordion, Icon, Span } from '@dnb/eufemia';
import { Dl, Dt, Dd } from '@dnb/eufemia/elements';
import { copy, profile } from '@dnb/eufemia/icons';
import { useCustomerInfo } from 'hooks/CustomerInfoContext';
import {
  CUSTOMER_PROFILE_INFO_TITLE,
  CUSTOMER_PROFILE_NAME,
  CUSTOMER_PROFILE_EMAIL,
  CUSTOMER_PROFILE_AGE,
  CUSTOMER_PROFILE_IS_DEAD,
  CUSTOMER_PROFILE_GENDER,
  CUSTOMER_PROFILE_IS_EMPLOYEE,
  CUSTOMER_PROFILE_MOBILE,
} from 'utils/constants';
import { copyUrl, showReadableBoolValue } from 'utils/helperFunction';
import {
  AccordianBorder,
  CustomerSectionProfile,
  CustomerProfileHeading,
  CustomerProfileActiveStatus,
  CopyButtonStyle,
} from './styles';

export const CustomerProfile = () => {
  const personInfo = useCustomerInfo();
  const [emailCopied, setEmailCopied] = useState(false);
  const [mobileCopied, setMobileCopied] = useState(false);
  const personAttributes = personInfo?.profile?.person;
  const emailAddress = personInfo?.profile?.personalEmail?.address;
  const mobileNumber = personInfo?.profile?.mobilePhone?.number;

  return (
    <CustomerSectionProfile>
      <Accordion expanded={true}>
        <Accordion.Header title={CUSTOMER_PROFILE_INFO_TITLE}>
          {CUSTOMER_PROFILE_INFO_TITLE}
        </Accordion.Header>
        <Accordion.Content>
          <AccordianBorder>
            <CustomerProfileHeading>
              <span>
                {personInfo?.activeStatus ? (
                  <CustomerProfileActiveStatus>
                    <Icon
                      icon={profile}
                      size="medium"
                      right="x-small"
                      color="var(--color-success-green)"
                    />
                    (Online)
                  </CustomerProfileActiveStatus>
                ) : (
                  <CustomerProfileActiveStatus>
                    <Icon
                      icon={profile}
                      size="medium"
                      left="0"
                      right="x-small"
                      color="var(--color-accent-yellow)"
                    />
                    (Offline)
                  </CustomerProfileActiveStatus>
                )}
              </span>
            </CustomerProfileHeading>
            <Dl>
              {personAttributes?.name?.firstName && (
                <>
                  {' '}
                  <Dt>{CUSTOMER_PROFILE_NAME}</Dt>
                  <Dd>
                    {personAttributes?.name?.firstName +
                      ' ' +
                      personAttributes?.name?.lastName}
                  </Dd>
                </>
              )}
              {emailAddress && (
                <>
                  <Dt>{CUSTOMER_PROFILE_EMAIL}</Dt>
                  <Dd>
                    <CopyButtonStyle
                      variant="secondary"
                      tooltip="Click to copy email address"
                      icon={copy}
                      icon_position="right"
                      icon_size={'medium'}
                      wrap
                      onClick={() => copyUrl(emailAddress, setEmailCopied)}
                    >
                      {emailAddress}
                    </CopyButtonStyle>
                    {emailCopied && <Span> Email address Copied </Span>}
                  </Dd>
                </>
              )}
              {mobileNumber && (
                <>
                  <Dt>{CUSTOMER_PROFILE_MOBILE}</Dt>
                  <Dd>
                    <CopyButtonStyle
                      variant="secondary"
                      tooltip="Click to copy mobile number"
                      icon={copy}
                      icon_position="right"
                      wrap
                      onClick={() => copyUrl(mobileNumber, setMobileCopied)}
                      icon_size={'medium'}
                    >
                      {mobileNumber}
                    </CopyButtonStyle>
                    {mobileCopied && <Span> Mobile Number Copied </Span>}
                  </Dd>
                </>
              )}
              {personAttributes?._dnb?.age && (
                <>
                  <Dt>{CUSTOMER_PROFILE_AGE}</Dt>
                  <Dd>{personAttributes?._dnb?.age}</Dd>
                </>
              )}
              {personAttributes?.gender && (
                <>
                  <Dt>{CUSTOMER_PROFILE_GENDER}</Dt>
                  <Dd>{personAttributes?.gender}</Dd>
                </>
              )}
              {personInfo?.profile?._dnb?.customerAttributes?.employeeFlag !==
                undefined && (
                <>
                  <Dt>{CUSTOMER_PROFILE_IS_EMPLOYEE}</Dt>
                  <Dd>
                    {showReadableBoolValue(
                      personInfo?.profile?._dnb?.customerAttributes
                        ?.employeeFlag
                    )}
                  </Dd>
                </>
              )}
              {personAttributes?._dnb?.isDeceasedInd !== undefined && (
                <>
                  <Dt>{CUSTOMER_PROFILE_IS_DEAD}</Dt>
                  <Dd>
                    {showReadableBoolValue(
                      personAttributes?._dnb?.isDeceasedInd
                    )}
                  </Dd>
                </>
              )}
            </Dl>
          </AccordianBorder>
        </Accordion.Content>
      </Accordion>
    </CustomerSectionProfile>
  );
};
