import React from 'react';
import { play_medium } from '@dnb/eufemia/icons';
import {
  DEVICE_INFO_APPLICATION_VERSION,
  DEVICE_INFO_BROWSER_NAME,
  DEVICE_INFO_BROWSER_VENDOR,
  DEVICE_INFO_BROWSER_VERSION,
  DEVICE_INFO_LANGUAGE,
  DEVICE_INFO_MANUFACTURER,
  DEVICE_INFO_OPERATING_SYSTEM,
  DEVICE_INFO_OPERATING_SYSTEM_VENDOR,
  DEVICE_INFO_OPERATING_SYSTEM_VERSION,
  DEVICE_INFO_PREVIEW_NOT_FOUND_MESSAGE,
  DEVICE_INFO_TYPE,
} from 'utils/constants';
import { CustomerContent } from '../CustomerProfile/styles';
import {
  DeviceInfoHeading,
  DeviceInfoDetails,
  DeviceInfoModal,
} from './styles';

export type DeviceInfoType = {
  deviceInfo: {
    deviceType?: string;
    language?:string;
    operatingSystem?: string;
    browserDetailsName?: string;
    browserDetailsVersion?: string;
    browserDetailsVendor?: string;
    operatingSystemVendor?: string;
    operatingSystemVersion?: string;
    manufacturer?: string;
    applicationVersion?: string;
  };
};
export const DeviceInfoPreview = ({ deviceInfo }: DeviceInfoType) => {
  const {
    deviceType,
    language,
    operatingSystem,
    browserDetailsName,
    browserDetailsVersion,
    browserDetailsVendor,
    operatingSystemVendor,
    operatingSystemVersion,
    manufacturer,
    applicationVersion,
  } = deviceInfo;

  return (
    <CustomerContent>
      <DeviceInfoModal
        triggerAttributes={{
          variant: 'secondary',
          text: 'Device Info',
          icon: play_medium,
          title: 'Device Info',
          icon_position: 'left',
        }}
        minWidth="45rem"
      >
        {
          <>
            {deviceType && (
              <>
                <DeviceInfoHeading>{DEVICE_INFO_TYPE}</DeviceInfoHeading>
                <DeviceInfoDetails>{deviceType}</DeviceInfoDetails>
              </>
            )}
            {browserDetailsName && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_BROWSER_NAME}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{browserDetailsName}</DeviceInfoDetails>
              </>
            )}
            {browserDetailsVersion && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_BROWSER_VERSION}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{browserDetailsVersion}</DeviceInfoDetails>
              </>
            )}
            {browserDetailsVendor && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_BROWSER_VENDOR}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{browserDetailsVendor}</DeviceInfoDetails>
              </>
            )}
            {language && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_LANGUAGE}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{language}</DeviceInfoDetails>
              </>
            )}
            {operatingSystem && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_OPERATING_SYSTEM}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{operatingSystem}</DeviceInfoDetails>
              </>
            )}
            {operatingSystemVendor && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_OPERATING_SYSTEM_VENDOR}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{operatingSystemVendor}</DeviceInfoDetails>
              </>
            )}
            {operatingSystemVersion && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_OPERATING_SYSTEM_VERSION}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{operatingSystemVersion}</DeviceInfoDetails>
              </>
            )}
            {manufacturer && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_MANUFACTURER}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{manufacturer}</DeviceInfoDetails>
              </>
            )}
            {applicationVersion && (
              <>
                <DeviceInfoHeading>
                  {DEVICE_INFO_APPLICATION_VERSION}
                </DeviceInfoHeading>
                <DeviceInfoDetails>{applicationVersion}</DeviceInfoDetails>
              </>
            )}
          </>
        }
        {!deviceType &&
          !applicationVersion &&
          !manufacturer &&
          !browserDetailsName &&
          !language && (
            <DeviceInfoDetails top bottom medium>
              {DEVICE_INFO_PREVIEW_NOT_FOUND_MESSAGE}
            </DeviceInfoDetails>
          )}
      </DeviceInfoModal>
    </CustomerContent>
  );
};
