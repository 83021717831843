import React, { useEffect, useState } from 'react';
import { Accordion, Icon, Table, Td, Th, Tr } from '@dnb/eufemia';
import { mobilephone, email, web, bubble } from '@dnb/eufemia/icons';
import {
  ExperienceEvent,
  EmailEvent,
  WebEvent,
  SpareEvent,
  ChannelNameType,
  SmsEvent,
  MobilBankEvent,
  MobileAppEvent,
} from 'hooks/CustomerInfoContext/types';
import {
  convertTimestampToDateFormat,
  convertTimestampToTimeFormat,
  getPathname,
  getPagename,
  getEventname,
  copyUrl,
} from 'utils/helperFunction';
import {
  CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_FIRST,
  CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_FOURTH,
  CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_SECOND,
  CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_THIRD,
  CUSTOMER_EVENTS_TIMELINE_TITLE,
  PAGE_NAME_CHARACTER_SIZE,
} from '../../utils/constants';
import DeviceInfoPreview from '../DeviceInfoPreview';
import { EmailPreview } from '../EmailPreview';
import { CXMSection } from '../Grid/styles';
import { SmsPreview } from '../SmsPreview';
import { FilterButton } from './FilterButton';
import {
  CopiedStyle,
  DateStyle,
  UrlButton,
  SourceIcon,
  SourceTableCell,
  TimelineAccordianBorder,
  TimelineProgress,
  TimelineTable,
  TimelineTableHeaders,
  TimeStyle,
  TimestampStyle,
} from './styles';

export const TimelinesCallcenter = ({
  partyID,
  sortByTimestampClickHandler,
  filterByChannelName,
  filterByTime,
  events,
}: {
  partyID: string;
  sortByTimestampClickHandler: () => void;
  filterByChannelName: (channel?: ChannelNameType) => void;
  filterByTime: (time?: string) => void;
  events: ExperienceEvent[] | undefined;
}) => {
  const [sortDirectionOnTimestamp, setSortDirectionOnTimestamp] =
    useState(false);
  const [areEventsFiltered, setAreEventsFiltered] = useState(false);
  const onTimestampClick = () => {
    sortByTimestampClickHandler();
    // Introduce a 200ms delay before updating the state this will be removed after lazy laoding
    setAreEventsFiltered(false);
    setTimeout(() => {
      setSortDirectionOnTimestamp(!sortDirectionOnTimestamp);
    }, 200);
  };

  const getEmailEventDescription = ({ to }: EmailEvent) => {
    return `${' To - ' + to}`;
  };

  const getMobileEventSource = (source: string | undefined) => {
    switch (source?.toLowerCase()) {
      case 'https://mobilbank.dnb.bank':
        return 'MobilBank DNB';
      case 'https://mobilbank.sbanken.no':
        return 'MobilBank Sbanken';
      case 'https://spare.dnb.bank':
        return 'Spare DNB';
      case 'https://spare.sbanken.no':
        return 'Spare Sbanken';
      default:
        return '';
    }
  };
  const getSmsEventDescription = ({
    messageName,
    contactDetails,
  }: SmsEvent) => {
    return (
      <div>
        <div>Journey name - {messageName}</div>
        <div>Mobile no.- {contactDetails}</div>
      </div>
    );
  };



  const GetPageEventDescription = ({ name }: MobilBankEvent | SpareEvent) => {
    const [copied, setCopied] = useState(false);
    const pageName = getPagename(name);
    const eventName = pageName && getEventname(pageName);
    const readablePageName = pageName?.slice(0, PAGE_NAME_CHARACTER_SIZE);
    if (eventName === 'Navigation') {
      const url = getPathname(name);
      return (
        <span>
          {url ? (
            <UrlButton
              tooltip={'Click to copy URL :' + url}
              onClick={() => copyUrl(url, setCopied)}
              variant="tertiary"
            >
              {readablePageName}
            </UrlButton>
          ) : (
            <span> {pageName}</span>
          )}
          {copied && <CopiedStyle> URL Copied </CopiedStyle>}
        </span>
      );
    } else {
      return (
        <span>
          <span> {name}</span>
        </span>
      );
    }
  };

  const getChannelIcon = (eventType: ExperienceEvent | undefined) => {
    switch (eventType?.channelName.toLowerCase()) {
      case 'email':
        return email;
      case 'web':
        return web;
      case 'sms':
        return bubble;
      case 'mobilbank':
      case 'spare':
        return mobilephone;
      default:
        return '';
    }
  };

  const sourceName = (eventType: ExperienceEvent | undefined) => {
    switch (eventType?.channelName.toLowerCase()) {
      case 'email':
        return eventType?.channelName;
      case 'web': {
        const event = eventType?.eventMetadata as WebEvent;
        return event?.webServer;
      }
      case 'sms':
        return eventType?.channelName;
      case 'mobilbank':
        const mobilbankEvent = eventType?.eventMetadata as MobilBankEvent;
        return getMobileEventSource(mobilbankEvent?.mobileServer);
      case 'spare':
        const spareEvent = eventType?.eventMetadata as SpareEvent;
        return getMobileEventSource(spareEvent?.mobileServer);
      default:
        return '';
    }
  };
  const descriptionContent = (eventType: ExperienceEvent | undefined) => {
    switch (eventType?.channelName.toLowerCase()) {
      case 'email':
        return getEmailEventDescription(eventType?.eventMetadata as EmailEvent);
      case 'web':
        const eventWebMetadata = eventType?.eventMetadata as WebEvent;
        const webPageName = eventWebMetadata?.name;
        return <GetPageEventDescription name={webPageName} />;
      case 'sms':
        return getSmsEventDescription(eventType?.eventMetadata as SmsEvent);
      case 'mobilbank':
        const eventMobileBankMetadata =
          eventType?.eventMetadata as MobilBankEvent;
        const mobilebankName = eventMobileBankMetadata?.name;
        return <GetPageEventDescription name={mobilebankName} />;
      case 'spare':
        const eventSpareMetadata = eventType?.eventMetadata as SpareEvent;
        const spareName = eventSpareMetadata?.name;
        return <GetPageEventDescription name={spareName} />;
      default:
        return '';
    }
  };

  const displayPreview = (eventData: ExperienceEvent | undefined) => {
    switch (eventData?.channelName.toLowerCase()) {
      case 'email':
        return (
          <EmailPreview
            eventData={eventData.eventMetadata as EmailEvent}
            partyID={partyID}
          />
        );
      case 'sms':
        return <SmsPreview eventData={eventData.eventMetadata as SmsEvent} />;
      case 'web':
      case 'spare':
      case 'mobilbank':
        return (
          <DeviceInfoPreview
            deviceInfo={eventData.eventMetadata as MobileAppEvent | WebEvent}
          />
        );
      default:
        return '';
    }
  };
  const handleFilterByChannelName = (filterChannelName?: ChannelNameType) => {
    setAreEventsFiltered(false);
    filterByChannelName(filterChannelName);
  };

  const handleFilterByTime = (time: string) => {
    filterByTime(time);
  };

  useEffect(() => {
    setAreEventsFiltered(true);
  }, [events]);

  return (
    <CXMSection>
      <Accordion expanded={true}>
        <Accordion.Header title={CUSTOMER_EVENTS_TIMELINE_TITLE}>
          {CUSTOMER_EVENTS_TIMELINE_TITLE}
        </Accordion.Header>
        <Accordion.Content>
          <FilterButton
            filterData={handleFilterByChannelName}
            filterByTime={handleFilterByTime}
            areEventsFiltered={areEventsFiltered}
          />

          <TimelineAccordianBorder>
            <Table.ScrollView style={{ height: '45rem', overflowX: 'hidden' }}>
              <TimelineTable sticky="css-position">
                <thead>
                  <Tr>
                    <TimelineTableHeaders
                      scope="col"
                      role="columnheader"
                      sortable
                      active={events && events.length > 0 ? true : false}
                      reversed={sortDirectionOnTimestamp}
                    >
                      <Th.SortButton
                        text={CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_FIRST}
                        name="sortevents"
                        onClick={onTimestampClick}
                      />
                    </TimelineTableHeaders>
                    <TimelineTableHeaders
                      scope="col"
                      role="columnheader"
                      colSpan={1}
                    >
                      {CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_SECOND}
                    </TimelineTableHeaders>
                    <TimelineTableHeaders
                      scope="col"
                      role="columnheader"
                      colSpan={2}
                    >
                      {CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_THIRD}
                    </TimelineTableHeaders>
                    <TimelineTableHeaders scope="col" role="columnheader">
                      {CUSTOMER_EVENTS_TIMELINE_TABLE_HEADING_FOURTH}
                    </TimelineTableHeaders>
                  </Tr>
                </thead>
                <tbody>
                  {!areEventsFiltered && (
                    <Tr variant="odd" expanded>
                      <Td colSpan={5}>
                        <TimelineProgress
                          type="linear"
                          top="xlarge"
                          left="xlarge"
                        />
                      </Td>
                    </Tr>
                  )}

                  {areEventsFiltered &&
                    (events?.length && events.length > 0 ? (
                      events?.map(
                        (eventData: ExperienceEvent, index: number) => (
                          <Tr key={index} variant="odd">
                            <Td scope="row" role="rowheader">
                              <SourceIcon>
                                <Icon
                                  color="var(--color-sea-green)"
                                  right="x-small"
                                >
                                  {getChannelIcon(eventData)}
                                </Icon>
                                <TimestampStyle>
                                  <DateStyle>
                                    {convertTimestampToDateFormat(
                                      eventData.timestamp
                                    )}
                                  </DateStyle>
                                  <TimeStyle>
                                    {convertTimestampToTimeFormat(
                                      eventData.timestamp
                                    )}
                                  </TimeStyle>
                                </TimestampStyle>
                              </SourceIcon>
                            </Td>
                            <SourceTableCell
                              scope="row"
                              role="rowheader"
                              colSpan={1}
                            >
                              {sourceName(eventData)}
                            </SourceTableCell>
                            <Td scope="row" role="rowheader" colSpan={2}>
                              {descriptionContent(eventData)}
                            </Td>
                            <Td scope="row" role="rowheader" colSpan={1}>
                              {displayPreview(eventData)}
                            </Td>
                          </Tr>
                        )
                      )
                    ) : (
                      <Tr variant="odd" expanded>
                        <Td colSpan={5}>No events found.</Td>
                      </Tr>
                    ))}
                </tbody>
              </TimelineTable>
            </Table.ScrollView>
          </TimelineAccordianBorder>
        </Accordion.Content>
      </Accordion>
    </CXMSection>
  );
};
