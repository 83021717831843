import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'
import { Button, ProgressIndicator, Table, Td, Th } from '@dnb/eufemia';
import { css } from '@emotion/react';


export const TimelineAccordianBorder = styled.div`
  display:table; 
  border: 1px solid var(--color-black-20);
  border-radius: 4px;
  padding:0;
`
export const TimelineTable = styled(Table)`
  margin-bottom: 0!important;
  table-layout:fixed!important;
  & > tbody > tr:not(:last-child) {
    border-bottom: 2px solid var(--color-pistachio);
    border-bottom-radius:2px;
  }
  & > tbody > tr > td {
    word-break: break-word!important;
  }
  ${media.phone`
    & > thead > tr > th, & > tbody > tr > td {
      font-size: var(--font-size-x-small)!important;
    }
  `}
`

export const FilterPanel = styled.div`
 display:flex;
 flex-direction:column;
`
export const FiterText = styled.span`
  font-size: var(--font-size-basis);
  font-weight:500;
  line-height: var(--line-height-basis);
  color: var(--color-black);
  margin:var(--spacing-small) var(--spacing-small);
  min-width: fit-content;
`

export const textFontSize = css`
  transition: all 0.3s ease-out;
`
export const FilterStyledButton = styled(Button)`
`
export const TimelineTableHeaders = styled(Th)`
  color: var(--color-emerald-green)!important;
`
export const TimestampStyle = styled.span`
  display:flex;
  flex-direction:column;
    
`
export const TimeStyle = styled.span`
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  text-align: right;
  color: #737373;
  position: relative;
`
export const DateStyle = styled.span`
  font-size: var(--font-size-basis);
  font-weight:400;
  line-height: var(--line-height-basis);
  color: var(--color-black);
  text-align: right;
`
export const CopiedStyle = styled.span`
  font-size: var(--font-size-small);
  line-height: var(--line-height-basis);
  color: var(--color-ocean-green);
  font-weight:var(--font-weight-medium)
`
export const TimelineProgress = styled(ProgressIndicator)`
    align:center;

`
export const SourceIcon = styled.span`
    display:flex;
    flex-direction:row;

`
export const SourceTableCell = styled(Td)`
   font-size: var(--font-size-small);

`
export const UrlButton = styled(Button)`
   padding: 0px!important;

`

