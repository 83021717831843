import React, { useState } from 'react';
import { ProgressIndicator, GlobalError, GlobalStatus } from '@dnb/eufemia';
import { GlobalStatusState } from '@dnb/eufemia/components/global-status/GlobalStatus';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import { CustomerEventDetails } from 'components/CustomerEventDetails/CustomerEventDetails';
import CustomerInformationProductOwnership from 'components/CustomerInformation';
import { CustomerMoreDetails } from 'components/CustomerInformation/styles';
import CustomerProfile from 'components/CustomerProfile';
import { CustomerContent } from 'components/CustomerProfile/styles';
import Grid, { FluidContainer } from 'components/Grid';
import { NextBestOfferSection } from 'components/NextBestOffers';
import PageTitle from 'components/PageTitle';
import Search from 'components/SearchCallcenter';
import { Error } from 'components/SearchCallcenter/types';
import { ThemeType } from 'components/layout/GlobalLayout';
import MainLayout from 'components/layout/MainLayout';
import { CustomerProfileWrapper, LoaderWrapper } from 'components/layout/styles';
import { CustomerInfoContext } from 'hooks/CustomerInfoContext';
import { CustomerInfoResult, CustomerProfileInfo } from 'hooks/CustomerInfoContext/types';
import { CXM_ERROR_MESSAGE_TEXT, CXM_SEARCH_INVALID_PARTYID_MESSAGE, CXM_SEARCH_PATH } from 'utils/constants';
import { pushPageLoadedEvent } from 'utils/datalayer';
import { getCustomerProfileFromQuery } from 'utils/graphql.utils';


const ERROR_STATE = {
  validation: {
    status: 'error',
    message: CXM_SEARCH_INVALID_PARTYID_MESSAGE,
  },
  partyID: {
    status: 'info',
    message: 'Party ID not found.',
  },
};

const SERVER_ERROR = {
  server: {
    status: '500',
    message: CXM_ERROR_MESSAGE_TEXT,
  },
};
const defaultTheme: ThemeType = {
  backgroundColor: 'var(--color-white)',
  color: 'var(--color-black)',
  logoColor: 'var(--color-sea-green)',
  linkColor: 'inherit',
};
export const SearchPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [errorState, setErrorState] =
    useState<{ status: string; message: string }>();
  const [serverError, setServerError] =
    useState<{ status: string; message: string }>();
  const [customerInfo, setCustomerInfo] = useState<CustomerProfileInfo>({
    profile: undefined,
    events: undefined,
    activeStatus: false,
  });
  const [partyIdentifier, setPartyID] = useState<string>();
  const [profileEcId, setProfileEcId] = useState<string>();
  async function getCustomerProfileFunction(
    input: string
  ): Promise<CustomerProfileInfo> {
    try {
      setShowLoader(true);
      const result: CustomerInfoResult = await getCustomerProfileFromQuery(
        input
      );
      const personInfo: CustomerProfileInfo = result?.data?.getCustomerProfile;
      pushPageLoadedEvent(
        CXM_SEARCH_PATH,
        {
          name: 'CXM Search',
          results: 1,
          term: input,
        },
        {
          party_id: input,
        }
      );
      setShowLoader(false);
      setCustomerInfo(personInfo);
      setProfileEcId(personInfo?.profile?._dnb?.customerAttributes?.ecId);
      return personInfo;
    } catch (e: any) {
      setShowLoader(false);
      setPartyID('');
      pushPageLoadedEvent(CXM_SEARCH_PATH, {
        name: 'CXM Search',
        results: 0,
        term: input,
      });
      if (e?.errors?.[0]?.errorType >= 400 && e?.errors?.[0]?.errorType < 500)
        setError({
          isValidationError: false,
          isPartyIDError: true,
          isServerError: false,
        });
      else
        setError({
          isValidationError: false,
          isPartyIDError: false,
          isServerError: true,
        });
      return customerInfo;
    }
  }

  function onSearchClickHandler(input: string) {
    setCustomerInfo({
      profile: undefined,
      events: undefined,
      activeStatus: false,
    });
    setProfileEcId(undefined);
    setPartyID(input);
    getCustomerProfileFunction(input);
  }

  const setError = ({
    isValidationError,
    isPartyIDError,
    isServerError,
  }: Error) => {
    setCustomerInfo({
      profile: undefined,
      events: undefined,
      activeStatus: false,
    });
    if (isValidationError) {
      setErrorState(ERROR_STATE.validation);
    } else if (isPartyIDError) {
      setErrorState(ERROR_STATE.partyID);
    } else if (isServerError) {
      setServerError(SERVER_ERROR.server);
    }
  };

  const resetError = (): void => {
    setErrorState(undefined);
  };

  return (
    <CustomerInfoContext.Provider value={customerInfo}>
      <MainLayout theme={defaultTheme}>
        <FluidContainer>
          {errorState && (
            <GlobalStatus
              id="search-error-component"
              show={errorState?.status !== undefined}
              text={errorState?.message}
              status_id="search-error"
              state={
                (errorState ? errorState.status : 'error') as GlobalStatusState
              }
            />
          )}
          <PageTitle />
          <Search
            onSearchClickHandler={onSearchClickHandler}
            setError={setError}
            resetError={resetError}
            validationErrorState={errorState}
          />
          {!showLoader ? (
            <CustomerProfileWrapper>
              <Grid>
                <CustomerContent>
                  {customerInfo?.profile?.person && <CustomerProfile />}
                  {customerInfo?.profile?._dnb?.customerAttributes && (
                    <CustomerInformationProductOwnership />
                  )}
                </CustomerContent>
                <CustomerMoreDetails>
                  {!errorState && partyIdentifier && (
                    <>
                      {profileEcId && (
                        <NextBestOfferSection
                          partyIdentifier={partyIdentifier}
                          ecId={profileEcId}
                        />
                      )}
                      <CustomerEventDetails partyIdentifier={partyIdentifier} />
                    </>
                  )}
                </CustomerMoreDetails>
              </Grid>
            </CustomerProfileWrapper>
          ) : (
            <LoaderWrapper>
              <ProgressIndicator
                visible={showLoader}
                type="circular"
                size="large"
              />
            </LoaderWrapper>
          )}
          {serverError && <GlobalError status="500" back={false} />}
        </FluidContainer>
      </MainLayout>
    </CustomerInfoContext.Provider>
  );
};

const SearchWithAuth = () => {
  return (
    <AuthenticatedRoute>
      <SearchPage />
    </AuthenticatedRoute>
  );
};

export default SearchWithAuth;