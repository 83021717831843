import styled from '@emotion/styled'
import { media } from '@dnb-shared-libs/responsive'
import { Button, Input } from '@dnb/eufemia';
import { GridContentCXM } from '../Grid/styles';

export const SearchBarContent = styled.div`
  display:flex;
  flex-direction: row;
`
export const SearchContainer = styled(Input)`
  background: var(--color-white);
  border-radius: 4px;
  margin-right: var(--spacing-small);
  font-size: var(--font-size-large);
`
export const SearchContent = styled(GridContentCXM)`

    grid-column: span 6;

  ${media.smallDesktop`
    grid-column: span 7;
  `}
  
  ${media.largeDesktop`
    grid-column: span 4;
  `}
`
export const SearchCXMButton = styled(Button)`
  height:fit-content;
  &:active{
    height:fit-content;
  }
`