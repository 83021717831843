export type CustomerInfoResult = {
  data: {
    getNextBestOffer: NextBestOffers;
    getCustomerProfile: CustomerProfileInfo;
  };
};
export type PreviewEmail = {
  data: {
    getEmailPreview: EmailPreview;
  };
};

type EmailPreview = {
  html: string;
  subject: string;
};
export type CustomerProfileInfo = {
  profile: Profile | undefined;
  events: ExperienceEvent[] | undefined;
  activeStatus: boolean
};

export type Profile = {
  person: Person;
  personalEmail: PersonalEmail;
  mobilePhone: MobilePhone;
  _dnb: DNBCustomProfileAttributes;
};

export type DNBCustomProfileAttributes = {
  customerAttributes: CustomerAttributes;
};

type Person = {
  name: CustomerName;
  gender: string;
  _dnb: DNBCustomPersonAttributes;
};

type DNBCustomPersonAttributes = {
  age: number;
  isDeceasedInd: boolean;
};

type PersonalEmail = {
  address: string;
};

type MobilePhone = {
  number: string
}

type CustomerName = {
  lastName: string;
  firstName: string;
};

type CustomerAttributes = {
  loyaltyProgramName: string;
  employeeFlag: boolean;
  bfaAssociation: BFAAssociation[];
  ecId: string;
};

type BFAAssociation = {
  name: string;
  code: string;
};

export enum EventType {
  EMAIL,
  WEB,
  MOBILE,
  SMS,
}
export enum ChannelNameType {
  WEB = 'WEB',
  EMAIL = 'EMAIL',
  MOBILBANK = 'MOBILBANK',
  SPARE = 'SPARE',
  SMS = 'SMS',
}

export enum TimeFilter {
  Today = 'Today',
  Yesterday = 'Yesterday',
  TwoWeeks = 'TwoWeeks',
  TwoHours = 'TwoHours',
  Reset = 'Reset',
}


export type ExperienceEvent = {
  timestamp: string;
  channelName: ChannelNameType;
  eventMetadata: AEPEventMetadata;
};
export type AEPEventMetadata = WebEvent | EmailEvent | MobilBankEvent | SpareEvent | SmsEvent;
export type EmailEvent = {
  to: string;
  sendTime: string;
  messageId: string;
};
export type WebEvent = {
  webServer?: string
  name: string
  deviceType?: string
  language?: string
  operatingSystem?: string
  browserDetailsName?: string
  browserDetailsVersion?: string
  browserDetailsVendor?: string
  operatingSystemVendor?: string
  operatingSystemVersion?: string

};

export type MobileAppEvent = {
  mobileServer?: string
  name: string
  deviceType?: string
  language?: string
  operatingSystem?: string
  manufacturer?: string
  applicationVersion?: string
  operatingSystemVendor?: string
  operatingSystemVersion?: string
}

export type MobilBankEvent = MobileAppEvent
export type SpareEvent = MobileAppEvent

export type SmsEvent = {
  messageName: string;
  contactDetails: string;
  messageContent: string;
};

export type NextBestOffer = {
  id: string;
  location: string;
  title: string;
  message: string;
  illustration: string;
  ctaLable: string;
  ctaLink: string;
}
export type NextBestOffers = {
  offers: [NextBestOffer]
}
